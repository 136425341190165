import ScaleLoader from "react-spinners/ScaleLoader";
import {useState,useEffect} from 'react';
import React from "react";
import './ContentStudioItem.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName, getCompletedInterviews, getCallReport, getContentStudioItems, getCompleteInterview, updateLikeStatus, generateContent, deleteContent, testPrompt, generateAnthropicContent } from '../../utils/api';
import { FaBriefcase, FaCalendarAlt, FaCaretLeft, FaCheckCircle, FaCloud, FaCopy, FaHeart, FaLinkedin, FaLock, FaMagic, FaRegCalendarCheck, FaRegCopy, FaRegEdit, FaRegHeart, FaRegLightbulb, FaRegThumbsDown, FaRegThumbsUp, FaSoundcloud, FaTrashAlt, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';

const ContentStudioItem = () => {
  const [interviews, setInterviews] = useState([]);
  const [interview, setInterview] = useState(null);
  const [items, setItems] = useState([]);
  const [journeyStep, setJourneyStep] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadedItems, setLoadedItems] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [status, setStatus] = useState('');
  let {id} = useParams();
  const navigate = useNavigate();

  async function loadItems(){
    let res = await getContentStudioItems(id);

    if (res.success){
      setItems(res.data);
      setLoadedItems(true);
    }
  }

  useEffect(() => {
    async function loadInterview(){
      let res = await getCompleteInterview(id);

      if (res.success){
        setInterview(res.data);
        if (res.data.content_generated == true)
          setShowBtn(false);
        else
          setShowBtn(true);
        setLoaded(true);
      }
    }

    async function loadItems(){
      let res = await getContentStudioItems(id);

      if (res.success){
        setItems(res.data);
        setLoadedItems(true);
      }
    }

    async function loadCallReport(){
      let res = await getCallReport(id);

      if (res.success){
        setStatus(res.data);
      }
    }

    loadInterview();
    loadCallReport();
    loadItems();
  }, []);

  async function handleGenerate(){
    setIsGenerating(true);

    let res = await generateAnthropicContent(id);

    if (res.success){
      setTimeout(function(){
        loadItems();
        setIsGenerating(false);
      }, 3000)

      setShowBtn(false);
    }
  }

  async function handleCopy(text, id){
    navigator.clipboard.writeText(text)
        .then(() => {
          document.getElementById('btncopy_' + id).innerHTML = 'Copied!';
          setTimeout(function(e) {
            document.getElementById('btncopy_' + id).innerHTML = `Copy <i class='fa fa-copy'></i>`;
          }, [1000])
           // alert("Text copied to clipboard!");
        })
        .catch(err => {
         //   alert("Failed to copy text.");
        });
  }

  async function handleLike(id){
    let res = await updateLikeStatus(id, true);
    loadItems();
  }

  async function handleUnlike(id){
    let res = await updateLikeStatus(id, false);
    loadItems();
  }

  async function handleDelete(id){
    let res = await deleteContent(id);

    if (res.success){
      loadItems();
    }
  }

  async function handleTestPrompt(){
    let res = await testPrompt();
    let posts = JSON.parse(res.data);
    console.log('res', res);
    console.log('posts', posts);
  }

  function RenderWithLineBreaks({ text }) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br />'),
        }}
      />
    );
  }

  return (
    <div className="contentstudioitem">
      <div className="mb-20">
        <span className="link" onClick={() => navigate('/account/studio')}><FaCaretLeft/> Back</span>
      </div>

      {/* <div>
        <button className='btn' onClick={() => handleTestPrompt()}>Test Prompt</button>
      </div> */}
      <div>
        {loaded == true && (
          <div>
              <div className="contentstudioitem__step-image">
                <img src={interview.image_url} />
              </div>

              <div className='contentstudioitem__step-name'>
                {interview.name}
              </div>

              <div className="contentstudioitem__step-description">
                <strong><FaCheckCircle /> Your interview date:</strong> {new Date(interview.date_created).toLocaleDateString()}
              </div>
          </div>
        )}

        {loaded == true && isGenerating == false && status == 'ended' && (
          <div className="center contentstudioitem__btn-generate-wrapper">
            <button className='btn contentstudioitem__btn-generate' onClick={() => handleGenerate()}><FaMagic style={{fontSize: '22px'}} /> <span className='ml-10'>Generate Content</span></button>
          </div>
        )}

        {loaded == true && isGenerating == false && showBtn == true && status != 'ended' && (
          <div className="center" style={{marginTop: '50px'}}>
            <i>CONTENT IS STILL BEING GENERATED. PLEASE WAIT 1-2 MINUTES.</i>
          </div>
        )}

        {loaded == true && isGenerating == true && (
          <div className="center contentstudioitem__btn-generate-wrapper">
            <Spinner />
          </div>
        )}

        <div>
          {loadedItems == false && (
            <div className="center">
              <Spinner />
            </div>
          )}

          {loadedItems == true && items.length > 0 && (
            <>
            <div className='contentstudioitem__items'>
              {items.map(item => (
                <div className="contentstudioitem__item">
                  <div className="contentstudioitem__item-copy">
                    <RenderWithLineBreaks text={item.content_body}></RenderWithLineBreaks>
                  {/* {item.content_body.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < item.content_body.split("\n").length - 1 && <br />}
                    </React.Fragment>
                  ))} */}
                  </div>

                  <div className='contentstudioitem__item-menu'>
                    <div className='contentstudioitem__item-btns'>
                      {item.is_liked == false && (
                      <div onClick={() => handleLike(item.journey_interview_content_id)}><FaRegHeart /></div>
                      )}

                      {item.is_liked == true && (
                        <div onClick={() => handleUnlike(item.journey_interview_content_id)}><FaHeart style={{color: 'salmon', fill: 'salmon'}} /></div>
                      )}
                    </div>

                    <div className="contentstudioitem__item-preview">
                      <button className='btn' onClick={() => handleDelete(item.journey_interview_content_id)}>
                        NOT GOOD
                      </button>

                      <button id={`btncopy_${item.journey_interview_content_id}`} className='ml-10 btn' onClick={() => handleCopy(item.content_body, item.journey_interview_content_id)}>Copy</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </>
          )}
        </div>
      </div>

      <div>
        <>
        {interviews.map((item, index) => (
            <div className='dashboard__journey' style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }}>
    
            <div className='dashboard__journey-title'>
              <h2>{index + 1}. {item.name}</h2>
    
              <div className='dashboard__journey-description'>
                 {item.description}
              </div>

              <div className='dashboard__journey-description'>
                Interview length: {item.min_duration}-{item.max_duration} mins
              </div>
            </div>
          </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default ContentStudioItem;
