import './Upgrade.css';
import { useState, useEffect } from "react";
import { loginUser } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import BuyerFeedback from '../../components/feedback/BuyerFeedback';
import SellerFeedback from '../../components/feedback/SellerFeedback';
import { useLocation } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, refreshToken, hRefreshToken, addUserEventTracking, verifyStl, addBuyerRole, createSubscription, checkSubscriptionStatus, checkHasPaymentMethod } from '../../utils/api';
import RescheduleTimes from "../../components/qualifier/RescheduleTimes";
import { FaAward, FaChartLine, FaCircle, FaCrown, FaHorseHead, FaRegCheckCircle, FaTimes } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import UpgradeCheckout from './UpgradeCheckout';
import Spinner from '../../components/layout/Spinner';

function Upgrade() {
    const [referenceId, setReferenceId] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [isBuyer, setIsBuyer] = useState(false);
    const [demo, setDemo] = useState({});
    const [valid, setValid] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [loadedPaymentMethod, setLoadedPaymentMethod] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [loadedSubscriptionCheck, setLoadedSubscriptionCheck] = useState(false);
    const [completed, setCompleted] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
      async function loadPaymentCheck()
      {
        let res = await checkHasPaymentMethod();

        if (res.success){
          setHasPaymentMethod(res.data);
          setLoadedPaymentMethod(true);
        }
      }

      async function loadSubscriptionCheck(){
        let res = await checkSubscriptionStatus();

        if (res.success){
          setHasSubscription(res.data);
          setLoadedSubscriptionCheck(true);
        }
      }

      loadPaymentCheck();
        loadSubscriptionCheck();
    }, []);

    async function handleConfirm(){
      setSubmitting(true);
      let res = await createSubscription();

      if (res.success){
       // alert('done');
       setSubmitting(false);
       setCompleted(true);
      }

      else{
       // alert('nope');
      }
    }

    async function handleUpgrade(){
      if (loadedPaymentMethod == true){
        if (hasPaymentMethod == false){
          navigate('/account/payment-method');
        }

        else{
          setShowConfirm(true);
        }
      }
    }

    async function handleRedirect(){
      window.location = '/journeys';
    }

    return (
        <div className="upgrade">
          <ScrollToTop />
          <Helmet>
            <meta name="description" content="" />
            <title>Upgrade to Pro Account</title>
          </Helmet>

          <div className='upgrade__header'>
            <h1>Upgrade to <span className='highlight'>PRO</span> today</h1>

            <p>
              
            </p>
          </div>

          <div className='upgrade__plans'>
            <div className='upgrade__plan'>
              <div className='upgrade__plan-name'>
                BASIC
              </div>

              <div className='upgrade__plan-price'>
                <span className='upgrade__plan-price-amount'>$0</span> / month
              </div>

              <div className='upgrade__plan-features'>
                <ul>
                  <li><FaCircle /> Access to The Founder's Journey</li>
                  <li><FaCircle /> 5-10 min personalized interviews</li>
                  <li><FaCircle /> Schedule interviews on your own time</li>
                  <li></li>
                </ul>
              </div>
            </div>

            <div className='upgrade__plan'>
              <div className='upgrade__plan-name'>
                <span className='highlight'>PRO</span>
              </div>

              <div className='upgrade__plan-price'>
                <span className='upgrade__plan-price-amount'>$59</span> / month
              </div>

              <div className='upgrade__plan-features'>
                <ul>
                  <li><FaCircle /> Start The Founder's Journey</li>
                  <li><FaCircle /> Get access to Journey's 2-5</li>
                  <li><FaCircle /> 5-10 min personalized interviews</li>
                  <li><FaCircle /> Schedule interviews on your own time</li>
                  <li><FaCircle /> High-quality LinkedIn content in minutes</li>
                  <li><FaCircle /> Retake interviews</li>
                  <li><FaCircle /> 24/7 customer support</li>
                  <li><FaCircle /> New features as soon as they are released</li>
                </ul>
              </div>

              <div className='upgrade__plan-btns'>
                {loadedSubscriptionCheck == true && hasSubscription == true && (
                  <div className='btn upgrade__already-subscribed'>
                    YOU'RE ON PRO
                  </div>
                )}

                {loadedSubscriptionCheck == true && hasSubscription == false && (
                  <button className='btn' onClick={() => handleUpgrade()}>UPGRADE</button>
                )}

              </div>
            </div>
          </div>

          <div className='center mt-20'>
            Cancel anytime.
          </div>

          {showConfirm && (
            <>
              <div className='overlay'></div>
              <div className='modal center'>
                <div className='modal__close' onClick={() => setShowConfirm(false)}>
                  <FaTimes />
                </div>

                {completed == false && (
                  <>
                <div className='center' style={{fontSize: '70px'}}>
                  <FaCrown />
                </div>

                <h2 className='modal__h2'>UPGRADE TO <span className='highlight'>PRO</span></h2>

                <div className='upgrade__plan-price'>
                  <span className='upgrade__plan-price-amount'>$59</span> / month
                </div>

                <div className='mt-20'>
                  {submitting == false && (
                    <button className='btn btn--confirm' onClick={() => handleConfirm()}>CONFIRM SUBSCRIPTION</button>
                  )}

                  {submitting == true && (
                    <div>
                      <Spinner />
                    </div>
                  )}
                </div>

                <div className='upgrade__disclosure'>
                  By clicking 'confirm', you agree to be charged the monthly subscription fee of $59 which will automatically be charged each month. You can cancel anytime.
                </div>
                </>
                )}

                {completed == true && (
                  <>
                    <div>
                    <div className='center' style={{fontSize: '70px'}}>
                      <FaCrown />
                    </div>

                    <h2 className='modal__h2 mt-20'><span className='highlight'>SUCCESS!</span></h2>

                    <p className='mt-20'>
                      You are now on the PRO plan and instantly get access to all journeys and interviews.
                    </p>

                    <div className='mt-20'>
                      <button className='btn btn--confirm' onClick={() => handleRedirect()}>TAKE ME TO MY DASHBOARD</button>
                    </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
    );
}

export default Upgrade;