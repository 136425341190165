import ScaleLoader from "react-spinners/ScaleLoader";
import {useState,useEffect} from 'react';
import './DemoConfirmation.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName } from '../../utils/api';
import { FaBrain, FaBriefcase, FaCalendarAlt, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaSoundcloud, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FirstAssistant from './FirstAssistant';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';
import imgLogo from '../../assets/design/yi_logo.png';

const ConfigureSettings = () => {
  const [preferredName, setPreferredName] = useState('');
  const [loadedName, setLoadedName] = useState(false);

  useEffect(() => {
    async function loadName(){
      let res = await getPreferredName();

    if (res.success){
      setPreferredName(res.data);
      setLoadedName(true);
    }
    }
    loadName();
  }, []);

  async function handleUpdate(){
    let res = await updateUser({preferred_name: preferredName});

    if (res.success){
      alert("Saved");
    }
  }

  return (
    <div>
      <h1>Configure</h1>

      <div className="form-group">
        <label>Preferred name</label>

        <div style={{
          fontSize: '12px',
          margin: '10px 0px'
        }}>
          This is the name that your personal interviewer will refer to you as.
        </div>

        <div>
          <input type='text' className="input form-control" value={preferredName} onChange={(e) => setPreferredName(e.target.value)} />
        </div>
      </div>

      <div className="form-group">
        <button className="btn btn--primary" onClick={() => handleUpdate()}>Update</button>
      </div>
    </div>
  );
};

export default ConfigureSettings;
