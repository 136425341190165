import './SetupForm.css';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { createTestCharge, setDefaultMethod } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';

const SetupForm = ({redirecturl = 'https://www.leadrpro.com/account/payment-method'}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error, paymentIntent, setupIntent} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirecturl,
      },
      redirect: "if_required"
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      if (setupIntent && setupIntent.payment_method) {
        let res = await setDefaultMethod(setupIntent.payment_method);

        if (res.success){
         // alert('done');
         navigate('/upgrade');
        }

        else{
        //  alert('error');
        setErrorMessage(error.message);

        }
      }

      else{
        //alert('no intent');
      }
    }

    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      {submitting == false && (
      <button className='btn btn--confirm' disabled={!stripe}>Submit</button>
      )}

      {submitting == true && (
        <div>
          <Spinner />
        </div>
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;