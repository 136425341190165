import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import './Homepage.css';
import medialogos from '../../assets/design/logos/medialogos.png';
import bg from '../../assets/design/homepage/background1.png';
import HomepageBuyer from './HomepageBuyer';
import HomepageSeller from './HomepageSeller';
import imgLogo from './assets/logo.png';
import imgSocials from './assets/sociallogos.png';
import imgPeople from './assets/people.png';
import imgGirl1 from './assets/girl1.png';
import imgSide from './assets/leftside.png';
import imgSs1 from './assets/ss1.png';
import imgSs2 from './assets/ss2.png';
import imgSs3 from './assets/ss3.png';

function Homepage() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div className='homepage'>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <div className='homepage__hero'>
        <div className='homepage__hero-left'>
          <img src={imgSide} alt='' />
        </div>

        <div className='homepage__hero-right'>
        <div className='homepage__hero-logo'>
          <img src={imgLogo} alt='' />
        </div>

        <div className='homepage__hero-title'>
          <div>Need to Create Content</div>
          <div className='highlight'>But Don't Know Where to Start?</div>
        </div>

        <div className='homepage__hero-subtitle'>
          Our <strong>AI Interviewer</strong> asks you thought provoking questions to extract your personal stories and then turns them into content optimized for engagement.

        </div>

        <div className='homepage__hero-btn-wrapper'>
          <button className='homepage__hero-btn'>
            Join Waitlist
          </button>
        </div>

        <div className='homepage__hero-people'>
          <div className='homepage__hero-people-image-wrapper'><img src={imgPeople} alt='' /></div>

          <div className='homepage__hero-people-count'>
            1K+ <div style={{fontSize: '16px'}}>and counting</div>
          </div>
        </div>

        <div className='homepage__hero-subtitle'>
          Create personalized, high engaging content for:
        </div>

        <div>
          <img src={imgSocials} alt='' />
        </div>
        </div>
      </div>

      <div className='homepage__section1'>
        <div className='homepage__section1-left'>
          <div className='homepage__section1-title'>
            <div>How Are We Different?</div>
            <div className='highlight'>No Direction Needed.</div>
          </div>

          <div className='homepage__section1-different'>
            <div>
              <h3>No Prompts</h3>

              <div className='homepage__section1-different-copy'>
                You know what you want. One-sided dialogue with AI prompt. Requirements: initiated by user, needs direction
              </div>
            </div>

            <div>
              <h3>No Chat AI</h3>

              <div className='homepage__section1-different-copy'>
                Back-and-forth dialogue to uncover new ideas and insights.  Requirements: initiated by user, needs direction
              </div>
            </div>
          </div>

          <div style={{padding: '20px'}}>
            <h3 className='highlight'>We Are Interview AI</h3>

            <p>
              In-depth back-and-forth dialogue that provokes critical thinking and descriptive details. Requirements: None. Initiated by AI, no direction needed
            </p>
          </div>

          <div style={{marginTop: '50px'}}>
            <span className='homepage__hero-btn'>
              Join Waitlist
            </span>
          </div>
        </div>

        <div className='homepage__section1-right'>
          <img src={imgGirl1} alt='' />
        </div>
      </div>

      <div className='homepage__section2'>
        <h2 className='homepage__section1-title'>Still Skeptical? <span className='highlight'>Here's Some Examples.</span></h2>

        <div className='homepage__section2-screenshots'>
          <div className='homepage__section2-screenshot'>
            <img src={imgSs1} alt='' />
          </div>

          <div>
            <img src={imgSs2} alt='' />
          </div>

          <div>
            <img src={imgSs3} alt='' />
          </div>
        </div>
      </div>

      <div className='homepage__section3'>
        <svg data-name="Shape Divider" data-testid="divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 23" preserveAspectRatio="none" className="wave-divider"><path d="M0 0C9.84 0 9.84 12 19.67 12C29.5 12 29.51 0 39.34 0C49.17 0 49.17 12 59.01 12C68.85 12 68.85 0 78.68 0C88.51 0 88.52 12 98.35 12C108.18 12 108.19 0 118.02 0C127.85 0 127.86 12 137.69 12C147.52 12 147.53 0 157.36 0C167.19 0 167.2 12 177.03 12C186.86 12 186.87 0 196.71 0C206.55 0 206.55 12 216.38 12C226.21 12 226.21 0 236.05 0C245.89 0 245.89 12 255.72 12C265.55 12 265.56 0 275.39 0C285.22 0 285.23 12 295.06 12C304.89 12 304.9 0 314.73 0C324.56 0 324.57 12 334.4 12C344.23 12 344.24 0 354.07 0C363.9 0 363.91 12 373.74 12C383.57 12 383.58 0 393.41 0C403.24 0 403.25 12 413.08 12C422.91 12 422.91 0 432.75 0C442.59 0 442.58 12 452.42 12C462.26 12 462.25 0 472.09 0C481.93 0 481.93 12 491.76 12C501.59 12 501.59 0 511.43 0C521.27 0 521.27 12 531.1 12C540.93 12 540.93 0 550.77 0C560.61 0 560.6 12 570.44 12C580.28 12 580.28 0 590.11 0C599.94 0 599.94 12 609.78 12C619.62 12 619.62 0 629.45 0C639.28 0 639.28 12 649.12 12C658.96 12 658.96 0 668.79 0C678.62 0 678.62 12 688.46 12C698.3 12 698.3 0 708.13 0C717.96 0 717.97 12 727.8 12C737.63 12 737.64 0 747.47 0C757.3 0 757.31 12 767.14 12C776.97 12 776.98 0 786.81 0C796.64 0 796.65 12 806.48 12C816.31 12 816.32 0 826.15 0C835.98 0 835.99 12 845.82 12C855.65 12 855.66 0 865.49 0C875.32 0 875.33 12 885.16 12C894.99 12 895 0 904.84 0C914.68 0 914.68 12 924.51 12C934.34 12 934.35 0 944.18 0C954.01 0 954.02 12 963.85 12C973.68 12 973.69 0 983.52 0C993.35 0 993.36 12 1003.19 12C1013.02 12 1013.03 0 1022.87 0C1032.71 0 1032.71 12 1042.54 12C1052.37 12 1052.38 0 1062.21 0C1072.04 0 1072.05 12 1081.89 12C1091.73 12 1091.73 0 1101.57 0C1111.41 0 1111.41 12 1121.25 12C1131.09 12 1131.09 0 1140.93 0C1150.77 0 1150.77 12 1160.61 12C1170.45 12 1170.45 0 1180.29 0C1190.13 0 1190.13 12 1199.97 12V23.5H0V0Z" className="wave"></path></svg>
        <div className='homepage__section3-title'>
          <div>11x Better Than Any Other</div>
          <div className='highlight'>AI Content Creator</div>
        </div>

        <div className='homepage__section3-copy'>
          Don't wait! Get an edge over your competition by becoming a content machine today. Schedule 10-15 mins per week with your AI Interviewer to generate hundreds of pieces of content monthly!
        </div>

        <div className='center' style={{marginTop: '50px'}}>
          <span className='homepage__hero-btn'>
            Join Waitlist
          </span>
        </div>

        <div className='homepage__section3-copyright'>
        ©2024 LeadrPro, Inc. DBA Your Interviewer. All rights reserved. Privacy Policy
        </div>
      </div>
    </div>
  );
}

export default Homepage;