import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ActiveCallDetail from "./ActiveCallDetail";
import Button from "./Button";
import Vapi from "@vapi-ai/web";
import { createInterview, getCallReport, interviewCompleted, getPreferredName } from "../../../utils/api";
import TypeWriter from "./TypeWriter";
import { FaPhoneAlt, FaPhoneSlash, FaTimes, FaStar, FaRegStar } from "react-icons/fa";
import FadeIn from "../../../utils/fadein";
import Spinner from "../../../components/layout/Spinner";
import imgLogo from '../../../assets/design/yi_logo.png';

// Put your Vapi Public Key below.
const apikey = 'f1d8542b-58ec-43ae-b785-aa00b53107a6';
const apikey2 = '88ace53c-5435-4dd2-9cc5-c5162246493e';
// const apikey = '54befc18-73ee-4840-980f-7e3e4f8d18c8';
//const vapi = new Vapi('88ace53c-5435-4dd2-9cc5-c5162246493e');
const key = process.env.REACT_APP_VAPI_PUBLIC_KEY;

const vapi = new Vapi(key);

const PreviewAssistant = ({showChat, setShowChat, journey_step_id, selectedStep, assistant_id, loadSteps}) => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [showCallEnded, setShowCallEnded] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [preferredName, setPreferredName] = useState('Bob');
  const [loadedName, setLoadedName] = useState(false);
  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();
  const [feedbackValue, setFeedbackValue] = useState(0);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const isPublicKeyMissingError = ({ vapiError }) => {
  return "Error";
};


  function handleFeedbackClick(i){
    setFeedbackValue(i);
    setFeedbackSubmitted(true);
  }

  function renderStars(){
    let arr = [];
    let _value = feedbackValue;

    for (let i = 0; i < 5; i++){
      if (i <= _value){
        arr.push(<span onClick={() => handleFeedbackClick(i) }><FaStar /></span>);
      }

      else{
        arr.push(<span onClick={() => handleFeedbackClick(i) }><FaRegStar /></span>);
      }
    }

    return (
      <div className='dashboard__star'>
        {arr}
      </div>
    )
  }

  useEffect(() => {
    async function loadName(){
      let res = await getPreferredName();

      if (res.success){
        setPreferredName(res.data);
        setLoadedName(true);
      }

      else{
        setPreferredName('');
        setLoadedName(true);
      }
    }

    loadName();
  }, []);
  // hook into Vapi events
  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      enableSound();

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", async () => {
      console.log('-----------end call-----------');
      setConnecting(false);
      setConnected(false);
      setCallEnded(true);
      setShowCallEnded(true);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
      disableSound();
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("message", (message) => {
      console.log('on message', message);

      if (message.type === 'end-of-call-report') {
        console.log('Call Analysis:', message.data);
        // Process the analysis data as needed
      }

     // console.log('analysis', vapi.call.analysis.summary);
    });

    vapi.on("error", (error) => {
      console.error('**********', error);

      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  // call start handler
  const startCallInline = () => {
    setConnecting(true);
    const analysisPlan = {
      summaryPrompt: "Provide a detailed summary of the interview highlighting key points.",
      structuredDataPrompt: "You will be given a transcript of a call and the system prompt of the AI participant. Extract the candidate's name and generate 9 linkedin posts that are genuine, personalized, written in the first person, and focused on using the format and style of the highest engaging posts on linkedin. focus on learnings, convictions, beliefs, personal stories, life lessons, advice, though leadership, expertise. make it highly polarizing and opinionated content with a definitive point of view. Incorporate line breaks, bullet lists and emojis.",
      structuredDataSchema: {
        type: "object",
        properties: {
          candidateName: { type: "string" },
          posts: {
            type: "array",
            items: { type: "string" },
            maxItems: 9
          }
        },
        required: ["candidateName"]
      },
      successEvaluationPrompt: "Evaluate the candidate's responses for clarity and ability to answer all of the questions.",
      successEvaluationRubric: "NumericScale" // Options could be "NumericScale", "DescriptiveScale", etc.
    };

    const assistantOverrides = {
      analysisPlan: analysisPlan,
      variableValues: {
        name: preferredName,
      },
    };

    // vapi.start(assistantOptions, assistantOverrides).then((response) => {
    //   setSessionId(response.id);
    //   handleCreateInterview(response.id);
    // });

    vapi.start(assistant_id, assistantOverrides).then((response) => {
      setSessionId(response.id);
      handleCreateInterview(response.id);
    });
  };
  const endCall = async () => {
    console.log('------ stopping vapi ----------');
    vapi.stop();
 
    let res1 = await interviewCompleted(sessionId);
    // if (res1.success){
    //   console.log('completed');
    // }

    // console.log('session id', sessionId);
    // let res = await getCallReport(sessionId);

    // if (res.success){
    //   console.log('call report done', res.data);
    // }
  };

  async function handleGetReport(){
    let res = await getCallReport(sessionId);

    if (res.success){
      console.log('call report done', res.data);
    }
  }

  async function handleCreateInterview(call_id){
    let res = await createInterview(journey_step_id, call_id);

    if (res.success){
      console.log('interview created');
    }
  }

  function enableSound(){
   // document.getElementById('soundwave').classList.add('active');
  }

  function disableSound(){
   // document.getElementById('soundwave').classList.remove('active');
  }

  function handleExit(){
   // loadSteps();
    setShowChat(false);
  }

  return (
    <div className='firstassistant'>
      {callEnded == false && showCallEnded == false && loadedName == true && (
        <>
        <div style={{
            textAlign: 'center',
            marginBottom:'50px'
          }}>
            <img src='https://ik.imagekit.io/h30rx6jhj/design/12113932-6950-4485-813f-3c7fc66b3a77.png?updatedAt=1732490562196' style={{
              height: '120px'
            }} alt='' />
          </div>

          <div>
            <TypeWriter text={`Hello! Let's start your interview!`} speed={60} />
          </div>

          {/* <h2 className='firstassistant__step-name center'>
            Name
          </h2> */}

      {connected == false && (
        <p className="mt-20">
          When ready click on the 'Start Interview' button below
        </p>
      )}

      <div>
        <div className='sound-icon enabled'>
  <div className={`sound-wave ${assistantIsSpeaking == true ? 'active': ''}`} id='soundwave'>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
    <i className='bar'></i>
  </div>
</div>
        </div>

    <div style={{
        justifyContent: "center",
        alignItems: "center",
      }}>
        
      {!connected ? (
        <>
        {connecting == false && (
          <button className='firstassistant__call-btn'
        onClick={startCallInline}>
            <FaPhoneAlt /> Let's Talk  
        </button>
        )}


        {connecting == true && (
          <span>
            <Spinner />
          </span>
        )}

        </>
      ) : (
        <>
        <div style={{ marginTop: "20px", textAlign: "center" }}>
        <button className='firstassistant__call-btn'
      onClick={endCall}>
          <FaPhoneSlash /> End Call
        </button>
      </div>
        </>
        // <ActiveCallDetail
        //   assistantIsSpeaking={assistantIsSpeaking}
        //   volumeLevel={volumeLevel}
        //   onEndCallClick={endCall}
        // />
      )}

      {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
      {/* <ReturnToDocsLink /> */}
    </div>
    </>
    )}

    {callEnded == true && showCallEnded == true && (
      <div>
        {/* <TypeWriter text="Your interview is now complete!" speed={60} /> */}

        {feedbackSubmitted == false && (
        <div className="" style={{marginTop: '40px'}}>
          <div><strong>How would you rate the call quality?</strong></div>
          <div className="mt-10">
            {renderStars()}
          </div>

          <div>

          </div>
        </div>
        )}

        {feedbackSubmitted == true && (
          <div style={{marginTop:' 40px'}}><strong>Thank you for your feedback!</strong></div>
        )}

        <FadeIn delay={300} duration={300}>
          <p className="mt-20">
            Here is your exclusive invite code as a thank you for trying us out!
          </p>

          <p className='mt-10' style={{fontSize: '26px', fontWeight: 'bold'}}>
            cbywsnzu
          </p>

          <p className='mt-10'>
            <Link to='/join-now' className='btn btn--primary'>Create Your Free Account</Link>
          </p>
        </FadeIn>
      </div>
    )}

      <div className='firstassistant__btn-exit mt-10'>
        <button className='btn' onClick={() => handleExit()}>Exit</button>
      </div>
    </div>
  );
};

const assistantOptions = {
  name: "Vapis Pizza Front Desk",
  firstMessage: "Hello {{name}}, are you ready to get started with your interview?",
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US",
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer",
  },
  model: {
    provider: "openai",
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `You are a voice assistant for Vappys Pizzeria, a pizza shop located on the Internet.

Your job is to take the order of customers calling in. The menu has only 3 types
of items: pizza, sides, and drinks. There are no other types of items on the menu.

1) There are 3 kinds of pizza: cheese pizza, pepperoni pizza, and vegetarian pizza
(often called "veggie" pizza).
2) There are 3 kinds of sides: french fries, garlic bread, and chicken wings.
3) There are 2 kinds of drinks: soda, and water. (if a customer asks for a
brand name like "coca cola", just let them know that we only offer "soda")

Customers can only order 1 of each item. If a customer tries to order more
than 1 item within each category, politely inform them that only 1 item per
category may be ordered.

Customers must order 1 item from at least 1 category to have a complete order.
They can order just a pizza, or just a side, or just a drink.

Be sure to introduce the menu items, don't assume that the caller knows what
is on the menu (most appropriate at the start of the conversation).

If the customer goes off-topic or off-track and talks about anything but the
process of ordering, politely steer the conversation back to collecting their order.

Once you have all the information you need pertaining to their order, you can
end the conversation. You can say something like "Awesome, we'll have that ready
for you in 10-20 minutes." to naturally let the customer know the order has been
fully communicated.

It is important that you collect the order in an efficient manner (succinct replies
& direct questions). You only have 1 task here, and it is to collect the customers
order, then end the conversation.

- Be sure to be kind of funny and witty!
- Keep all your responses short and simple. Use casual language, phrases like "Umm...", "Well...", and "I mean" are preferred.
- This is a voice conversation, so keep your responses short, like in a real conversation. Don't ramble for too long.`,
      },
    ],
  }
};

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};

export default PreviewAssistant;
