import './Footer.css';
import logo from '../../assets/design/yi_logo.png';
import logo_white from '../../assets/design/logo_white.png';
import ios from '../../assets/design/ios.png';
import googleplay from '../../assets/design/googleplay.png';
import { Link } from 'react-router-dom';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <>
      <div className='footer'>
        <div className='footer__links'>
          <div className='footer__links-column'>
            <div className='footer__logo'>
              <img src={logo} alt='Company logo' />
            </div>
          </div>

          <div className='footer__links-column'>
            <h3>Support</h3>

            <div>
              <a href='/contact'>Contact</a>
            </div>
          </div>



          <div className='footer__links-column'>
            <h3>Documentation</h3>

            <div>
              <a href='/terms'>Terms</a>
              <a href='/privacy'>Privacy Policy</a>
              <a href='/policies'>Policies</a>
            </div>
          </div>

          {/* <div className='footer__links-column'>
            <h3>Mobile App</h3>

            <div>
              <a href='https://www.leadrpro.com/app'>Download the app</a>
            </div>

            <div className='footer__app-icons'>
            <a href='https://apps.apple.com/us/app/leadrpro/id6449265881' target='_blank'>
              <img
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/appsotre.png?updatedAt=1682699351000'
              />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile' target='_blank'>
              <img
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/google-play-badge__3_.png?updatedAt=1682699483535'
              />
            </a>
            </div>

            <div style={{marginTop: '30px'}}>
            <a href="https://www.producthunt.com/posts/leadrpro-for-events?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-leadrpro&#0045;for&#0045;events" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=431555&theme=light&period=daily" alt="LeadrPro&#0032;for&#0032;Events - The&#0032;networking&#0032;app&#0032;for&#0032;events | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
            </div>
          </div> */}
        </div>

        <div className='footer__copyright'>
          &copy; {year} YourInterviewer - All Rights Reserved
        </div>
      </div>
    </>
  );
}

export default Footer;
