import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneys, getJourney, updateJourney } from '../../utils/api/admin';

function JourneyEdit({journey_id}) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  //const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getJourney(journey_id);

      if (res.success){
        let data = res.data[0];
        setData(data);

        setName(data.name);
        setDescription(data.description);
        setImageUrl(data.image_url);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleUpdate(){
    let res = await updateJourney(journey_id, name, description, '');

    if (res.success){

    }
  }

  return (
    <>
      <Helmet>
        <title>Journeys</title>
      </Helmet>

      <h1>Journeys {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div>
            <div className='form-group'>
              <label>Name</label>
              <input type='text' className='input' value={name} />
            </div>

            <div className='form-group'>
              <label>Description</label>
              <textarea type='text' className='input' value={description}></textarea>
            </div>

            <div className='form-group'>
              <div>
                <img style={{width: '100%'}} src={`${imageUrl}`} />
              </div>
            </div>

            <div className='form-group'>
              <label>Image URL</label>
              <input type='text' className='input' value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
            </div>

            <div className='form-group'>
              <button className='btn' onClick={() => handleUpdate()}>Update</button>
            </div>
          </div>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default JourneyEdit;