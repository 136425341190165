import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_live_51QP5b6KriUeDZ7zaOwo1XCJmoZ9FEXEJDxc91zUANdtEtgjrbEhVqlwQ71DKe3j1iBd12vtWTJHGb9sLolDc539r006hFPnegk');

function UpgradeCheckout() {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
}

export default UpgradeCheckout;
