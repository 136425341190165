import ScaleLoader from "react-spinners/ScaleLoader";
import {useState,useEffect} from 'react';
import './ContentStudio.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName, getCompletedInterviews } from '../../utils/api';
import { FaBrain, FaBriefcase, FaCalendarAlt, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaSoundcloud, FaSpinner, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FirstAssistant from './FirstAssistant';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';
import imgLogo from '../../assets/design/yi_logo.png';

const ContentStudio = () => {
  const [preferredName, setPreferredName] = useState('');
  const [loadedName, setLoadedName] = useState(false);
  const [interviews, setInterviews] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadInterviews(){
      let res = await getCompletedInterviews(1);

      if (res.success){
        setInterviews(res.data);
        setLoaded(true);
      }
    }
    async function loadName(){
      let res = await getPreferredName();

    if (res.success){
      setPreferredName(res.data);
      setLoadedName(true);
    }
    }
    loadName();
    loadInterviews();
  }, []);

  async function handleClick(id){
    navigate(`/account/studio/${id}`);
  }

  return (
    <div className='contentstudio'>
      <h1>Content Studio</h1>

      <div className="contentstudio__copy">
        View, manage and edit your generated content.
      </div>

      {loaded == false && (
        <div className="center">
            <Spinner />
        </div>
      )}
      <div>
        <>
        {loaded && interviews.length == 0 && (
          <>
              <div>
                <i>You have not generated any content yet. Complete your <Link className="link" to={'/journeys'}>first interview</Link> to get started.</i>
              </div>
          </>
        )}

        {loaded && interviews.map((item, index) => (
            <div className='contentstudio__item dashboard__journey' onClick={()=> handleClick(item.call_id)} style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }}>
    
            <div className='dashboard__journey-title'>
              <h2>{index + 1}. {item.name}</h2>
    
              <div className='dashboard__journey-description'>
                 {item.description}
              </div>

              <div className='dashboard__journey-description'>
                Interview length: {item.min_duration}-{item.max_duration} mins
              </div>
            </div>
    
         
          </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default ContentStudio;
