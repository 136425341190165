import './JoinNow.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { getUserWaitlist, getSeniorityLevels, createUserJoinNow, searchCompaniesDropdown, 
  createCompanyUser, createCompany, getDepartments, refreshToken, getCompanySizes, saveUserProfile, updateUser, checkInviteToken, earlyAccessRequest
} from '../../utils/api';
import { isAuthed } from '../../services/auth.service';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaRegBell, FaTimes } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import imgCoins from '../../assets/rewards/lpcoins.jpg';
import imglogo from '../../assets/design/yi_logo.png';

function JoinNow() {
  const [loading, setLoading] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const [userSource, setUserSource] = useState('');
  const [showReferral, setShowReferral] = useState(false);
  const [referrer, setReferrer] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [password, setPassword] = useState('');
    const [optIn, setOptIn] = useState(false);
    const [agree, setAgree] = useState(false);
    const [seniority, setSeniority] = useState([]);
    const [showDropdown, setShowDropdown]= useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [query, setQuery] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [seniorityId, setSeniorityId] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [companyExists, setCompanyExists] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedCompanySize, setSelectedCompanySize] = useState(null);
    const [companySizes, setCompanySizes] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [token, setToken] = useState('');
    const [loadedToken, setLoadedToken] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showEmailCapture, setShowEmailCapture] = useState(false);
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [requestEmail, setRequestEmail] = useState('');
    const [requestName, setRequestName] = useState('');
    
    const navigate = useNavigate();

    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      let _domain = 'domain=leadrpro.com';
      document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
    }

  async function handleSourceChange(val){
    setUserSource(val);

    if (val === 'Referral'){
        setShowReferral(true);
    }
}

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  useEffect(() => {
    if (isAuthed()){
      navigate('/journeys')
    }

    async function loadSeniority(){
      let res = await getSeniorityLevels();

      if (res.success){
        setSeniority(res.data);
      }
    }

    async function loadDepartments() {
      let data = await getDepartments();
      if (data.data) {
        setDepartments(data.data);
      }
    }

    async function loadCompanySizes() {
      let data = await getCompanySizes();
      if (data.data){
          setCompanySizes(data.data);
      }
    }

    loadSeniority();
    loadDepartments();
    loadCompanySizes();
  }, []);

  async function handleSubmit(e){
    e.preventDefault();

    setLoading(true);
    setSubmitting(true);

    let el1 = e.target.elements.email.value;
    let el2 = e.target.elements.optIn.value;

    let channel_token = getCookie('channel_token');
    //let partner_key = getCookie('ps_gspk');
    let partner_key = getCookie('ps_partner_key');
    let click_id = getCookie('ps_xid');
    let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (el1.length === 0){
        setHasError(true);
        setSubmitting(false);
        return;
    }

    let data= {email: el1, password: password, optIn: el2, promoCode: promoCode, source: userSource, channel_token, timezone: l_timezone, referrer, partner_key, click_id, invite_token: token};
    let res  = await createUserJoinNow(data);

    if (res.success){
      localStorage.setItem('access_token', res.data.accessToken);
      localStorage.setItem('refresh_token', res.data.refreshToken);

      // used for meetings.leadrpro
      setCookie('access_token', res.data.accessToken, 1);
      setCurrentStep(2);
    }
    else{
        setLoading(false);
        setHasError(true);
        setErrorMessage(res.message);
    }

    setSubmitting(false);
}

async function companySearch(query){
  let data = await searchCompaniesDropdown(query);

  if (data.data){
    setCompanies(data.data);
  }
}

     

      // selecting company from dropdown
      async function handleCompanyClick(id, name){
        setSelectedCompany(id);
        setShowDropdown(false);
        setQuery(name);
        //setShowCompanyDetails(false);
      }

      async function handleSubmit2(e){
        e.preventDefault();

        if (selectedCompany !== 0){
          setSubmitting(true);

          // company exists
      //    setCompanyExists(true);
        //  setCompanyId(selectedCompany);

   //       let res1 = await createCompanyUser(selectedCompany);

          // if (res1.status === 403){
          //   let _refresh = await refreshToken();

          //   if (_refresh.success){
          //     localStorage.setItem('access_token', _refresh.data.accessToken);
          //     localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          //     let res1 = await createCompanyUser(selectedCompany);
          //   }
          // }

          await updateUserInfo();

          setSubmitting(false);
          //setShowSuccess(true);
          window.location = '/journeys';
        }

        else if(query.trim().length > 0){
          setSubmitting(true);

          // new company
          setCompanyExists(false);
          let company_size_id = selectedCompanySize
          let industry_id = 159;
          let result = await createCompany(query, company_size_id, industry_id, '', '');

          if (result.success){
            setCompanyId(result.data);
          }

          else if (result.status === 403){
            let _refresh = await refreshToken();

            if (_refresh.success){
              localStorage.setItem('access_token', _refresh.data.accessToken);
              localStorage.setItem('refresh_token', _refresh.data.refreshToken);

              let result2 = await createCompany(query, company_size_id, industry_id, '', '');

              if (result2.success){
                setCompanyId(result2.data);
              }
            }
          }

          await updateUserInfo();
          setShowSuccess(true);
        }

        else{
          setSubmitting(false);
          setHasError(true);
          setErrorMessage("Must select a valid company name");
          return;
        }
    }

    async function updateUserInfo(){

      let res = await saveUserProfile(jobTitle, seniorityId , selectedDepartment, '');

      if (res.success){
        await updateUser({first_name: firstName, last_name: lastName});

        setSubmitting(false);
      }
    }

    async function checkInviteCode(){
      //alert(token);
      let res = await checkInviteToken(token);

      if (res.success){
        setTokenIsValid(res.data);

        if (res.data == true){
          setShowForm(true);
          setShowError(false);
        }

        else{
          setShowError(true);
        }
      }
    }

    async function handleJoinWaitlist(){
      if (requestEmail != ''){
        let res = await earlyAccessRequest(requestEmail, requestName);

        if (res.success){
          setRequestEmail('');
          setRequestName('');
        }

        setEmailSubmitted(true);
      }
    }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Join Now</title>
      </Helmet>

      <ScrollToTop />

    {showForm == false && tokenIsValid == false && (
      <div className='joinnow__early-access'>
        <div>
          <div className='joinnow__left-header'>
            <span className='highlight'>YourInterviewer</span> is currently in early access.
          </div>

          <div className='mt-20'>
            <div>Enter your invite code if availble:</div>
            
            <div className='mt-10'>
              <input type='text' value={token} onChange={(e) => setToken(e.target.value)} className='form-control input joinnow__input-code' placeholder='Invite code' />
            </div>
            <div className='mt-10'>
              <button className='btn btn--primary' onClick={() => checkInviteCode()}>Verify Code</button>
            </div>

            {showError == true && (
              <div className='joinnow__error-msg'>
                <strong>Invite code is invalid.</strong>
              </div>
            )}
          </div>
        </div>

        {emailSubmitted == false && (
        <div>
          <div className='joinnow__left-header'>
            Don't have an invite code? Get added to the waitlist.
          </div>

          <p>
            Be the next in line to use YourInterviewer to generate your highest converting content to date.
          </p>

          <div className='mt-20'>
            <div>
              <div><strong>Name</strong></div>
              <div>
                <input type='text' className='input' placeholder='Name' value={requestName} onChange={(e) => setRequestName(e.target.value)} />
              </div>
            </div>

            <div className='mt-10'>
              <div><strong>Email Address</strong></div>
              <div>
                <input type='text' className='input' placeholder='Email address' value={requestEmail} onChange={(e) => setRequestEmail(e.target.value)} />
              </div>
            </div>

            <div className='mt-10'>
              <button className='btn btn--primary' onClick={() => handleJoinWaitlist()}>Join the waitlist</button>
            </div>
          </div>
        </div>
        )}

        {emailSubmitted == true && (
          <div className='' style={{padding: '5% 0px', fontSize: '20px', fontStyle: 'italic'}}>
            <strong>You're on the waitlist! Keep an eye on your inbox for your early access code.</strong>
          </div>
        )}
      </div>
    )}

    {currentStep == 1 && showForm == true && (
      <div className='joinnow__card'>
        <div className='joinnow__left'>
          <div className='joinnow__left-logo'>
            <img src={imglogo} alt='aa' />
          </div>

          <div className='joinnow__left-header'>
            Create your free account
          </div>

          <form onSubmit={handleSubmit}>
          <div className='joinnow__form'>
            <div className='joinnow__form-group'>
              <label>Email</label>
              <div>
                <input name='email' type='email' className='input' required placeholder='Email address' />
              </div>
            </div>

            <div className='joinnow__form-group'>
              <label>Password</label>
              <div>
                <input name='password' type='password' className='input' required placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>

            <div className='joinnow__form-password-help'>
              <div>
                Use 6 or more characters
              </div>

              <div>
                One special character
              </div>

              <div>
                One number
              </div>
            </div>

            <div className='form-group'>
                <label htmlFor='txtPromo'>Promo code (optional)</label>
                <input type='text' className='input' id='txtPromo' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} placeholder='Code' />
            </div>

            <div className='form-group'>
                <label htmlFor='selectSource'>How did you hear about us?</label>
                <select className='input select' id='selectSource' onChange={(e) => handleSourceChange(e.target.value)}>
                    <option value='0'>Select option...</option>
                    <option value='ProductHunt'>Product Hunt</option>
                    <option value='LinkedIn'>LinkedIn</option>
                    <option value='Facebook'>Facebook</option>
                    <option value='Google'>Google</option>
                    <option value='Email'>Email</option>
                    <option value='Ad'>Ad</option>
                    <option value='LeadrProBlog'>LeadrPro Blog</option>
                    <option value='News Article'>Article</option>
                    <option value='Webinar'>Attended Webinar</option>
                    <option value='Referral'>Referral</option>
                    <option value='Other'>Other</option>
                </select>
            </div>

            {showReferral && (
                <>
                    <div className='form-group'>
                        <label>Name and company of peson who referred you</label>
                        <input type='text' className='input' placeholder='Referrer' value={referrer} onChange={(e) => setReferrer(e.target.value)}/>
                    </div>
                </>
            )}

            <div className='form-group'>
                <input type='checkbox' id="radioOptIn" name="optIn" checked={optIn} value={optIn} onChange={() => setOptIn(!optIn)} />

                <label className='createaccount__radio-label label' htmlFor='radioOptIn'>Yes, please keep me updated on news, events and offers
                </label>
            </div>

            <div className='joinnow__form-disclaimer'>
              By creating an account, you agree to the <Link to='/terms' className='link'>Terms of Use</Link> and <Link to='/privacy' className='link'>Privacy Policy</Link>.
            </div>

            {hasError === true && (
                <div className="form-group">
                    <span className="label--error">{errorMessage}</span>
                </div>
            )}

            <div className='joinnow__form-btn'>
              {submitting == false && (
                <button className='btn btn--primary'>Create account</button>
              )}

              {submitting && (
                <Spinner />
              )}
            </div>

            <div className='mt-30'>
              Already have an account? <Link to='/login' className='link'>Sign in</Link>
            </div>
          </div>
          </form>
        </div>
      </div>
    )}

      {currentStep == 2 && (
        <FadeIn delay={300} duration={300}>
      <div className='joinnow__card'>
        <div className='joinnow__left'>
          <div className='joinnow__left-header'>
            One last step and you're done!
          </div>

          <form onSubmit={handleSubmit2}>
          <div className='joinnow__form'>
            <div class='joinnow__form-row'>
              <div>
                <label>First name</label>
                <input type='text' className='input' required placeholder='First name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </div>

              <div>
                <label>Last name</label>
                <input type='text' className='input' required placeholder='Last name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>

            <div className='joinnow__form-row'>
              <div>
                <label>Job Title</label>
                <input type='text' className='input' required placeholder='Job title' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
              </div>
            </div>

            <div>
            {/* <div style={{position: 'relative'}}>
                <label>Company Name</label>
                <div className=''>
                <input type="text" id='txtName' className="input" onChange={handleChange} placeholder="Enter here" value={query} autoComplete='off' required></input>

                {companies.length > 0 && showDropdown && (
              <div className="selectrole__company-dropdown-wrapper">
              <div className="selectrole__company-dropdown">
                  {companies.map((item, index) => (
                      <div key={index} onClick={() => handleCompanyClick(item.company_id, item.name)} className="selectrole__company-dropdown-item" id={"company_" + item.company_id}>
                        <span style={{height: "30px", width: "30px", overflow: "none", borderRadius: "50%", display: "inline-block", verticalAlign: "middle"}}>
                          <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} style={{width: "100%"}} />
                        </span>
                        <span style={{marginLeft: "10px", verticalAlign: "middle", width: "80%"}}>{item.name}</span>
                      </div>
                  ))}
                  </div>
              </div>
              )}
              </div>
              </div> */}
            </div>

            <div className='joinnow__form-btn mt-20'>
              {submitting == false && showSuccess == false && (
                <button className='btn btn--primary'>Submit</button>
              )}

              {submitting && (
                <Spinner />
              )}
            </div>
          </div>
          </form>
        </div>
        </div>
        </FadeIn>
      )}

      {showSuccess && (
        <FadeIn delay={300} duration={300}>
          <>
            <div className='overlay'></div>
            <div className='modal center joinnow__modal-success'>
              <div className='center'>
                <img src={imgCoins} alt='' style={{height: '160px', borderRadius: '10px'}} />
              </div>

              <div className='joinnow__modal-success-header mt-20'>
                Congratulations!<br/>
                You have earned <span>+1 points!</span>
              </div>

              <div className='mt-10'>
                Start exploring new software solutions and earn more points today!
              </div>

              <div className='mt-20'>
                <button className='btn btn--primary' onClick={() => window.location = '/dashboard/buyer'}>
                  Start exploring
                </button>
              </div>
            </div>
          </>
        </FadeIn>
      )}
    </>
  );
}

export default JoinNow;